<!-- 组件模板内容 -->
<template>
  <div>
    <SjGoods ref="SjGoods" v-show="isJd==2"></SjGoods>
    <JdGoods ref="JdGoods" v-show="isJd==1"></JdGoods>
  </div>
</template>

<script>
import JdGoods from "./component/JdGoods.vue";
import SjGoods from "./component/SjGoods.vue";
export default {
  name: "GoodInfoComponent",
  components: {SjGoods, JdGoods},
  // 组件的data属性
  data() {
    return {
      isJd:1
    };
  },

  // 组件的方法
  methods: {
    openGoodsInfo(id,isJd,hideSupplPrice){
      this.isJd=isJd
      if (isJd==1) {
        this.$refs.JdGoods.getJdGoodsInfo(id,isJd,hideSupplPrice)
      }else{
        this.$refs.SjGoods.getSjGoodsInfo(id,isJd,hideSupplPrice)
      }
    }
  },
  // 在created钩子中执行的操作
  created() {},
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>