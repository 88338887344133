import { render, staticRenderFns } from "./shopGoodsModal.vue?vue&type=template&id=20f01e8d&scoped=true"
import script from "./shopGoodsModal.vue?vue&type=script&lang=js"
export * from "./shopGoodsModal.vue?vue&type=script&lang=js"
import style0 from "./shopGoodsModal.vue?vue&type=style&index=0&id=20f01e8d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f01e8d",
  null
  
)

export default component.exports